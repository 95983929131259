import React, { useEffect, useState } from "react";
import useApiCallHook from "../Helpers/useApiCallHook";
import DashBoardBox from "./DashBoardBox";
import useToastHook from "../Helpers/useToastHook";

function Analytics() {
  const { getAnalyticsData } = useApiCallHook();
  const { ToastService } = useToastHook();
  const [analyticsData, setAnalyticsData] = useState();
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  const fetchAndSetAnalytics = async () => {
    const response = await getAnalyticsData();
    if (response) {
      setAnalyticsData({
        "Total Reminders Set": response?.totalReminderSet,
        "Avg. Set Reminders per Active User":
          response?.avgReminderPerActiveUser,
        "Total Reminders Completed": response?.totalReminderCompleted,
        "Avg. Completed Reminders per Active User":
          response?.avgReminderCompletedPerActiveUser,
        "Total Reminders Pending": response?.totalReminderPending,
        "Avg. Pending Reminders per Active User":
          response?.avgReminderPendingPerActiveUser,
        "Total Repeats": response?.totalRepeats,
        "% Repeats per Reminders ": response?.avgRepeatsPerReminder,
        "Total Add to Calendars": response?.totalAddToCalendar,
        "% Add to Calendar per Reminders": response?.avgAddToCalendar,
        "Total Cancels": response?.totalCancelled,
        "% Cancels per Reminders": response?.avgCancelled,
        "Paid Users": response?.totalPaidUsers,
        "Trial Users": response?.totalTrialUsers,
        "Number of 24 Hour Reminders": response?.reminderUnder24Hour,
        "Number of >24 Hour Reminders": response?.reminderMoreThan24Hour,
      });
    }
  };

  const fetchAndSetAnalyticsByDate = async () => {
    if (!fromDate || !toDate) {
      return window.alert("Please enter valid date range");
    }
    const appendVal = "T23:59:59.000Z";
    const endDate = new Date(toDate).toISOString();
    let val = `startDate=${new Date(fromDate).toISOString()}&endDate=${
      endDate.split("T")[0] + appendVal
    }`;
    ToastService.showInfoToast({
      title: "Fetching Data...",
    });
    const response = await getAnalyticsData(val);
    if (response) {
      setAnalyticsData({
        "Total Reminders Set": response?.totalReminderSet,
        "Avg. Set Reminders per Active User":
          response?.avgReminderPerActiveUser,
        "Total Reminders Completed": response?.totalReminderCompleted,
        "Avg. Completed Reminders per Active User":
          response?.avgReminderCompletedPerActiveUser,
        "Total Reminders Pending": response?.totalReminderPending,
        "Avg. Pending Reminders per Active User":
          response?.avgReminderPendingPerActiveUser,
        "Total Repeats": response?.totalRepeats,
        "% Repeats per Reminders ": response?.avgRepeatsPerReminder,
        "Total Add to Calendars": response?.totalAddToCalendar,
        "% Add to Calendar per Reminders": response?.avgAddToCalendar,
        "Total Cancels": response?.totalCancelled,
        "% Cancels per Reminders": response?.avgCancelled,
        "Paid Users": response?.totalPaidUsers,
        "Trial Users": response?.totalTrialUsers,
        "Number of 24 Hour Reminders": response?.reminderUnder24Hour,
        "Number of >24 Hour Reminders": response?.reminderMoreThan24Hour,
      });
      ToastService.showSuccessTaost({
        title: "Data Fetched",
      });
    }
  };

  useEffect(() => {
    fetchAndSetAnalytics();
  }, []);

  return (
    <div
      style={{
        paddingTop: 0,
      }}
    >
      <hr
        style={{
          height: "2px",
          width: "100%",
          marginBottom: "20px",
          color: "darkgray",
        }}
      />
      <div
        style={{ display: "flex", marginBottom: "10px", paddingLeft: "20px" }}
      >
        <div style={{ marginRight: "10px" }}>
          <label style={{ marginRight: "10px" }}>From Date:</label>
          <input
            type="date"
            id="fromDate"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
            style={{
              padding: "8px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          />
        </div>
        <div style={{ marginRight: "10px" }}>
          <label style={{ marginRight: "10px" }}>To Date:</label>
          <input
            type="date"
            id="toDate"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
            style={{
              padding: "8px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          />
        </div>
        <button
          style={{
            padding: "8px 16px",
            backgroundColor: "#4CAF50",
            color: "white",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
          }}
          onClick={fetchAndSetAnalyticsByDate}
        >
          Fetch
        </button>
      </div>
      <hr
        style={{
          height: "2px",
          width: "100%",
          marginTop: "20px",
          color: "darkgray",
        }}
      />
      <div
        style={{
          display: "flex",
          gap: "24px",
          alignItems: "center",
          padding: "20px",
          flexWrap: "wrap",
        }}
      >
        {analyticsData ? (
          Object.entries(analyticsData).map(([key, value]) => {
            return (
              <DashBoardBox
                heading={key}
                value={value}
                background={"white"}
                width={"160px"}
                height={"100px"}
                key={key}
              />
            );
          })
        ) : (
          <h2>Analytics Stats Loading...</h2>
        )}
      </div>
    </div>
  );
}

export default Analytics;
