import React, { useEffect, useState } from "react";
import { CloseIcon, CheckIcon, LinkIcon } from "@chakra-ui/icons";
import useApiCallHook from "../Helpers/useApiCallHook";
import DashBoardBox from "./DashBoardBox";
import useToastHook from "../Helpers/useToastHook";

function SubscriptionReport() {
  const { ToastService } = useToastHook();
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [fromDateStats, setFromDateStats] = useState();
  const [toDateStats, setToDateStats] = useState();
  const [paymentStats, setPaymentStats] = useState();
  const { getPaymentHistory, getPaymentDashboardData } = useApiCallHook();

  const tableHeaderStyle = {
    backgroundColor: "#f2f2f2",
    padding: "12px",
    border: "1px solid #ddd",
  };

  const tableCellStyle = {
    padding: "12px",
    border: "1px solid #ddd",
  };

  const formatValue = (value, key, user) => {
    if (key === "Amount Paid") {
      return `$${value}`;
    }
    if (typeof value === "boolean" && !value) {
      return <CloseIcon color={"red"} />;
    }
    if (typeof value === "boolean" && value) {
      return <CheckIcon color="green" />;
    }
    if (typeof value === "string" && value.includes("https")) {
      return (
        <LinkIcon
          onClick={() => {
            user["Payment Success"]
              ? ToastService.showInfoToast({
                  title: "Payment is already done",
                })
              : window.open(value);
          }}
          color={"black"}
        />
      );
    }

    return value;
  };

  const fetchSubscriptionReport = () => {
    getPaymentHistory().then((res) => {
      setSubscriptionList(
        res?.map((obj) => {
          return {
            Name: obj?.user?.username || "",
            Email: obj?.user?.email || "",
            "Phone Number": obj?.user.phone ? `+${obj?.user.phone}` : 0,
            "Amount Paid": obj?.amountPaid || 0,
            "Payment Success": !!obj?.paymentSuccess,
            "Payment Link": obj?.paymentLink || "",
          };
        })
      );
    });
  };

  const getPaymentStatsByDate = () => {
    if (!fromDateStats || !toDateStats) {
      return window.alert("Please enter valid date range");
    }
    const appendVal = "T23:59:59.000Z";
    const endDate = new Date(toDateStats).toISOString();
    let val = `startDate=${new Date(fromDateStats).toISOString()}&endDate=${
      endDate.split("T")[0] + appendVal
    }`;
    ToastService.showInfoToast({
      title: "Fetching Data...",
    });
    getPaymentDashboardData(val)
      .then((res) => {
        setPaymentStats({
          "Payment Links Sent": res?.totalPaymentLinkGenerated,
          "Payment Links Used": res?.totalPaymentSuccess,
          "Payment Link Conversion Rate %": res?.paymentLinkConversionRate,
          "Total Sales Value $": res?.totalSaleValue,
          "Total Sales Volume": res?.totalSaleVolume,
          "Avg. Sales Price $": res?.avgSalePrics,
        });
        ToastService.showSuccessTaost({
          title: "Data fetched successfully",
        });
      })
      .catch((err) => {
        ToastService.showErrorToast({
          title: "Error",
        });
      });

    getPaymentHistory(val)
      .then((res) => {
        setSubscriptionList(
          res?.map((obj) => {
            return {
              Name: obj?.user?.username || "",
              Email: obj?.user?.email || "",
              "Phone Number": obj?.user.phone ? `+${obj?.user.phone}` : 0,
              "Amount Paid": obj?.amountPaid || 0,
              "Payment Success": !!obj?.paymentSuccess,
              "Payment Link": obj?.paymentLink || "",
            };
          })
        );
        ToastService.showSuccessTaost({
          title: "Data fetched successfully",
        });
      })
      .catch((err) => {
        ToastService.showErrorToast({
          title: "Error",
        });
      });
  };

  const fetchPaymentStats = () => {
    getPaymentDashboardData().then((res) => {
      setPaymentStats({
        "Payment Links Sent": res?.totalPaymentLinkGenerated,
        "Payment Links Used": res?.totalPaymentSuccess,
        "Payment Link Conversion Rate %": res?.paymentLinkConversionRate,
        "Total Sales Value $": res?.totalSaleValue,
        "Total Sales Volume": res?.totalSaleVolume,
        "Avg. Sales Price $": res?.avgSalePrics,
      });
    });
  };

  useEffect(() => {
    fetchSubscriptionReport();
    fetchPaymentStats();
  }, []);

  return (
    <div style={{ paddingLeft: "20px" }}>
      <hr
        style={{
          height: "2px",
          width: "100%",
          marginBottom: "20px",
          color: "darkgray",
        }}
      />
      <div style={{ display: "flex", marginBottom: "10px" }}>
        <div style={{ marginRight: "10px" }}>
          <label style={{ marginRight: "10px" }}>From Date:</label>
          <input
            type="date"
            id="fromDate"
            value={fromDateStats}
            onChange={(e) => setFromDateStats(e.target.value)}
            style={{
              padding: "8px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          />
        </div>
        <div style={{ marginRight: "10px" }}>
          <label style={{ marginRight: "10px" }}>To Date:</label>
          <input
            type="date"
            id="toDate"
            value={toDateStats}
            onChange={(e) => setToDateStats(e.target.value)}
            style={{
              padding: "8px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          />
        </div>
        <button
          style={{
            padding: "8px 16px",
            backgroundColor: "#4CAF50",
            color: "white",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
          }}
          onClick={getPaymentStatsByDate}
        >
          Fetch
        </button>
      </div>
      <hr
        style={{
          height: "2px",
          width: "100%",
          marginTop: "20px",
          color: "darkgray",
        }}
      />
      <div
        style={{
          display: "flex",
          gap: "20px",
          alignItems: "center",
          padding: "20px 20px 20px 0",
          marginBottom: "20px",
        }}
      >
        {paymentStats ? (
          Object.entries(paymentStats).map(([key, value]) => {
            return (
              <DashBoardBox
                heading={key}
                value={value}
                key={key}
                height={"100px"}
                width={"150px"}
                background={"white"}
              />
            );
          })
        ) : (
          <h2>Stats Loading...</h2>
        )}
      </div>

      <div style={{ marginTop: "20px" }}>
        {subscriptionList.length ? (
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
            }}
          >
            <thead>
              <tr>
                {Object.keys(subscriptionList[0])?.map((key) => (
                  <th key={key} style={{ ...tableHeaderStyle }}>
                    {key}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody style={{ textAlign: "center" }}>
              {subscriptionList.map((user, index) => (
                <tr key={index}>
                  {Object.entries(user).map(([key, value], subIndex) => (
                    <td key={subIndex} style={{ ...tableCellStyle }}>
                      {formatValue(value, key, user)}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No Subscription found</p>
        )}
      </div>
    </div>
  );
}

export default SubscriptionReport;
