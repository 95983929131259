import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import useApiCallHook from "../Helpers/useApiCallHook";
import { CheckIcon, CloseIcon, LinkIcon } from "@chakra-ui/icons";

function UserDetailPage() {
  const { number } = useParams();
  const navigate = useNavigate();
  const { getReminderHistory, getSubscriptionReport } = useApiCallHook();
  const [remainderHistory, setRemainderHistory] = useState();
  const [subscriptionReport, setSubscriptionReport] = useState();

  const tableHeaderStyle = {
    backgroundColor: "#f2f2f2",
    padding: "12px",
    border: "1px solid #ddd",
  };

  const tableCellStyle = {
    padding: "12px",
    border: "1px solid #ddd",
  };

  useEffect(() => {
    if (number) {
      getReminderHistory(number)
        .then((res) =>
          setRemainderHistory(
            res.map((item) => {
              return {
                "Reminder Date/Time": item?.date
                  ? new Date(item.date).toUTCString()
                  : "",
                "Reminder Message": item?.messageText,
                Active: item?.active,
                Repeating: item?.isRepeating,
                "Create Date/Time": item?.createdAt
                  ? new Date(item.createdAt).toUTCString()
                  : "",
              };
            })
          )
        )
        .catch((err) => console.log(err));
      getSubscriptionReport(number)
        .then((res) => setSubscriptionReport(...res))
        .catch((err) => console.log(err));
    }
  }, [number]);

  const formatValue = (value, key) => {
    if (typeof value === "boolean" && !value) {
      return <CloseIcon color={"red"} />;
    }
    if (typeof value === "boolean" && value) {
      return <CheckIcon color="green" />;
    }
    if (typeof value === "string" && value.includes("https")) {
      return <LinkIcon onClick={() => window.open(value)} color={"black"} />;
    }

    return value;
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }
  }, []);

  return (
    <div style={{ padding: "20px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <h1
          style={{
            color: "#333",
            fontSize: "24px",
            fontWeight: "bold",
          }}
        >
          User Details
        </h1>
        <button
          style={{
            padding: "8px 20px",
            border: "1px solid gray",
            borderRadius: "8px",
          }}
          onClick={() => navigate("/dashboard")}
        >
          Go To Home
        </button>
      </div>
      <hr
        style={{
          height: "2px",
          width: "100%",
          marginBottom: "20px",
          color: "darkgray",
        }}
      />

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 2fr",
          gap: "8px",
          alignItems: "center",
        }}
      >
        <p style={{ fontWeight: "bold" }}>Name:</p>
        <p>{subscriptionReport?.username}</p>
        <p style={{ fontWeight: "bold" }}>Phone:</p>
        <p>{subscriptionReport?.phone}</p>
        <p style={{ fontWeight: "bold" }}>Email:</p>
        <p>{subscriptionReport?.email}</p>
        <p style={{ fontWeight: "bold" }}>Current Credits:</p>
        <p>{subscriptionReport?.credits}</p>
        <p style={{ fontWeight: "bold" }}>Credits Bought:</p>
        <p>{subscriptionReport?.totalCredits}</p>
        <p style={{ fontWeight: "bold" }}>Total Amount Paid:</p>
        <p>${subscriptionReport?.totalAmountPaid}</p>
        <p style={{ fontWeight: "bold" }}>Payment Links Generated:</p>
        <p>{subscriptionReport?.paymentLinksGenerated}</p>
        <p style={{ fontWeight: "bold" }}>Payment Links Paid:</p>
        <p>{subscriptionReport?.paymentLinksPaid}</p>
      </div>

      <div style={{ margin: "48px 0 24px 0" }}>
        <h1
          style={{
            color: "#333",
            fontSize: "24px",
            marginBottom: "20px",
            fontWeight: "bold",
          }}
        >
          Reminder History
        </h1>
        <hr
          style={{
            height: "2px",
            width: "100%",
            marginBottom: "20px",
            color: "darkgray",
          }}
        />

        {remainderHistory?.length ? (
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
            }}
          >
            <thead>
              <tr>
                {Object.keys(remainderHistory[0])?.map((key) => (
                  <th key={key} style={{ ...tableHeaderStyle }}>
                    {key}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody style={{ textAlign: "center" }}>
              {remainderHistory.map((userObj, index) => (
                <tr key={index}>
                  {Object.entries(userObj).map(([key, value], subIndex) => (
                    <td key={subIndex} style={{ ...tableCellStyle }}>
                      {formatValue(value, key)}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No Remainder found</p>
        )}
      </div>
    </div>
  );
}

export default UserDetailPage;
