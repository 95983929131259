import React from "react";

function DashBoardBox({ heading, value, height, width, background }) {
  return (
    <div
      key={heading}
      className="card"
      style={{
        height: height ? height : "180px",
        width: width ? width : "180px",
        ...(background ? { background: background } : ""),
        padding: "8px",
      }}
    >
      <div
        style={{ fontWeight: "bold", marginBottom: "5px", fontSize: "14px" }}
      >
        {heading}
      </div>
      <div>{value}</div>
    </div>
  );
}

export default DashBoardBox;
