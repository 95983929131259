import { useToast } from "@chakra-ui/react";

const useToastHook = () => {
  const toast = useToast();

  const ToastService = {
    showSuccessTaost: ({ title, message }) => {
      toast({
        title: title,
        description: message,
        variant: "solid",
        position: "top-right",
        isClosable: true,
        status: "success",
        duration: 3000,
      });
    },
    showErrorToast: ({ title, message }) => {
      toast({
        title: title,
        description: message,
        variant: "solid",
        position: "top-right",
        status: "error",
        isClosable: true,
        duration: 3000,
      });
    },
    showInfoToast: ({ title, message }) => {
      toast({
        title: title,
        description: message,
        duration: 3000,
        position: "top-right",
        variant: "subtle",
        status: "info",
      });
    },
  };

  return { ToastService };
};

export default useToastHook;
