import React from "react";
import SearchBar from "./SearchBar";
import UserCard from "./UserCard";
import useHelperFunctionsHook from "../Helpers/useHelperFunctionsHook";
import useApiCallHook from "../Helpers/useApiCallHook";
import { useState, useEffect } from "react";
import {
  CheckIcon,
  CloseIcon,
  HamburgerIcon,
  LinkIcon,
  WarningIcon,
} from "@chakra-ui/icons";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { MdPermMedia } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import DashBoardBox from "./DashBoardBox";
import useToastHook from "../Helpers/useToastHook";

function User() {
  const navigate = useNavigate();
  const { ToastService } = useToastHook();
  const { getUser, getWhatsappLogs, getUserDashboardData, getUserLocations } =
    useApiCallHook();
  const [userSearch, setUserSearch] = useState("");
  const [userDashboardStats, setUserDashboardStats] = useState();
  const [userList, setUserList] = useState([]);
  const [userChat, setUserChat] = useState([]);
  const [userNumber, setUserNumber] = useState("");
  const [loadingChat, setLoadingChat] = useState("");
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [userLocationModal, setUserLocationModal] = useState(false);
  const [userLocationList, setUserLocationList] = useState([]);

  const tableHeaderStyle = {
    backgroundColor: "#f2f2f2",
    padding: "12px",
    border: "1px solid #ddd",
  };

  const tableCellStyle = {
    padding: "12px",
    border: "1px solid #ddd",
  };

  const fetchAndSetUserByDate = async () => {
    if (!fromDate || !toDate) {
      return window.alert("Please enter valid date range");
    }
    ToastService.showInfoToast({
      title: "Fetching Data...",
    });
    const appendVal = "T23:59:59.000Z";
    const endDate = new Date(toDate).toISOString();
    let val = `startDate=${new Date(fromDate).toISOString()}&endDate=${
      endDate.split("T")[0] + appendVal
    }`;
    const response = await getUser(val);
    const responseUserStat = await getUserDashboardData(val);
    if (response) {
      setUserList(
        response.data.map((item) => {
          return {
            Name: item?.username ?? "-",
            Email: item?.email ?? "-",
            Phone: item?.phone ?? "-",
            Location: item.country ?? "-",
            New: item.isNew,
            Active: item.isActive,
            "Reminders Set": item.totalReminderSet,
            "Reminders Completed": item.totalReminderCompleted,
            "Current Credits": item?.credits ?? 0,
            "Whatsapp Chat Logs": (
              <Button onClick={() => setUserNumber(item?.phone)}> View </Button>
            ),
            Details: (
              <Button onClick={() => navigate(`/userDetails/${item?.phone}`)}>
                View Details
              </Button>
            ),
          };
        })
      );
      ToastService.showSuccessTaost({
        title: "Data Fetched",
      });
    }
    if (responseUserStat) {
      setUserDashboardStats({
        New: responseUserStat.newUsers,
        Active: responseUserStat.totalActiveUsers,
        "Conversion Rate": responseUserStat.conversionRate,
        "Total Locations": responseUserStat.totalLocations,
      });
    }
  };

  const fetchAndSetUser = async () => {
    const response = await getUser();
    if (response) {
      setUserList(
        response.data.map((item) => {
          return {
            Name: item?.username ?? "-",
            Email: item?.email ?? "-",
            Phone: item?.phone ?? "-",
            Location: item.country ?? "-",
            New: item.isNew,
            Active: item.isActive,
            "Reminders Set": item.totalReminderSet,
            "Reminders Completed": item.totalReminderCompleted,
            "Current Credits": item?.credits ?? 0,
            "Whatsapp Chat Logs": (
              <Button onClick={() => setUserNumber(item?.phone)}> View </Button>
            ),
            Details: (
              <Button onClick={() => navigate(`/userDetails/${item?.phone}`)}>
                View Details
              </Button>
            ),
          };
        })
      );
    }
  };

  const formatValue = (value, key) => {
    if (typeof value === "boolean" && !value) {
      return <CloseIcon color={"red"} />;
    }
    if (typeof value === "boolean" && value) {
      return <CheckIcon color="green" />;
    }
    if (typeof value === "string" && value.includes("https")) {
      return <LinkIcon onClick={() => window.open(value)} color={"black"} />;
    }

    return value;
  };

  const filteredUser = () => {
    if (!userSearch) return userList;
    return userList?.filter((user) =>
      user?.Name?.toLowerCase().includes(userSearch.toLowerCase())
    );
  };

  const getUserDashboardStats = async () => {
    const response = await getUserDashboardData();
    if (response) {
      setUserDashboardStats({
        New: response.newUsers,
        Active: response.totalActiveUsers,
        "Conversion Rate": response.conversionRate,
        "Total Locations": response.totalLocations,
      });
    }
  };

  const fetchMsg = () => {
    setLoadingChat("");
    getWhatsappLogs(userNumber)
      .then((res) => {
        if (res.length) {
          setUserChat(
            res?.map((obj) => {
              return {
                text: obj?.messageText,
                sender: obj?.sender,
                time: obj?.createdAt
                  ? new Date(obj?.createdAt).toDateString()
                  : "",
                interactive: obj?.interactive,
                messageType: obj?.messageType,
              };
            })
          );
          setLoadingChat("success");
        } else {
          setLoadingChat("no-chat");
        }
      })
      .catch((err) => {
        setLoadingChat("no-chat");
      });
  };

  const fetchUserLocations = async () => {
    const response = await getUserLocations();
    if (response) {
      setUserLocationList(
        response.map((item) => {
          return {
            [item._id]: [item.users],
          };
        })
      );
    }
  };

  useEffect(() => {
    fetchAndSetUser();
    getUserDashboardStats();
    fetchUserLocations();
  }, []);

  useEffect(() => {
    if (userNumber) {
      fetchMsg();
    }
  }, [userNumber]);

  return (
    <div>
      <hr
        style={{
          height: "2px",
          width: "100%",
          marginBottom: "10px",
          color: "darkgray",
        }}
      />
      <div
        style={{
          padding: "8px 20px 24px 8px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <SearchBar setSearchValues={setUserSearch} />
        <div style={{ display: "flex", marginBottom: "10px" }}>
          <div style={{ marginRight: "10px" }}>
            <label style={{ marginRight: "10px" }}>From Date:</label>
            <input
              type="date"
              id="fromDate"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              style={{
                padding: "8px",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
            />
          </div>
          <div style={{ marginRight: "10px" }}>
            <label style={{ marginRight: "10px" }}>To Date:</label>
            <input
              type="date"
              id="toDate"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              style={{
                padding: "8px",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
            />
          </div>
          <button
            style={{
              padding: "8px 16px",
              backgroundColor: "#4CAF50",
              color: "white",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
            onClick={fetchAndSetUserByDate}
          >
            Fetch
          </button>
        </div>
      </div>

      <hr
        style={{
          height: "2px",
          width: "100%",
          color: "darkgray",
        }}
      />
      <div
        style={{
          display: "flex",
          gap: "20px",
          alignItems: "center",
          padding: "20px 0 0 0",
          marginBottom: "20px",
        }}
      >
        {userDashboardStats ? (
          Object.entries(userDashboardStats).map(([key, value]) => {
            return (
              <DashBoardBox
                heading={key}
                value={value}
                height={"80px"}
                width={"150px"}
                key={key}
              />
            );
          })
        ) : (
          <h2>Stats Loading...</h2>
        )}

        {userDashboardStats ? (
          <div
            className="card"
            style={{
              height: "80px",
              width: "150px",
              padding: "8px",
            }}
          >
            <div style={{ fontWeight: "bold", marginBottom: "5px" }}>
              User Locations
            </div>
            <button
              style={{
                padding: "4px 8px",
                borderRadius: "8px",
                background: "#4CAF50",
                color: "white",
                fontSize: "14px",
              }}
              onClick={() => {
                setUserLocationModal(true);
              }}
            >
              Check Locations
            </button>
          </div>
        ) : null}
      </div>

      <div
        style={{
          overflow: "scroll",
          paddingBottom: "60px",
        }}
      >
        {userList?.length ? (
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
            }}
          >
            <thead>
              <tr>
                {Object.keys(userList[0])?.map((key) => (
                  <th key={key} style={{ ...tableHeaderStyle }}>
                    {key}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody style={{ textAlign: "center" }}>
              {filteredUser().map((userObj, index) => (
                <tr key={index}>
                  {Object.entries(userObj).map(([key, value], subIndex) => (
                    <td
                      key={subIndex}
                      style={{ ...tableCellStyle, hyphens: "auto" }}
                    >
                      {formatValue(value, key)}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <h1> No user found! </h1>
        )}
      </div>

      <Modal
        isOpen={userNumber.length}
        sx={{
          maxHeight: "500px !important",
          heigth: "500px !important",
          maxWidth: "400px !important",
          width: "400px !important",
          overflow: "scroll",
          padding: "8px",
          borderRadius: "8px",
          border: "1px solid darkgray",
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Text>Whatsapp Chat</Text>
            <CloseIcon
              onClick={() => {
                setUserNumber("");
                setUserChat([]);
              }}
            />
          </ModalHeader>
          <ModalBody>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: userChat.length ? "flex-end" : "center",
                overflow: "scroll",
                maxHeight: "500px",
                padding: "12px",
              }}
            >
              {userChat.length ? (
                userChat.map((message, index) => (
                  <div
                    key={index}
                    style={{
                      margin: "5px",
                      padding: "8px",
                      borderRadius: "8px",
                      background:
                        message.sender === "user" ? "#DCF8C6" : "#ECE5DD",
                      alignSelf:
                        message.sender === "user" ? "flex-end" : "flex-start",
                      maxWidth: "70%",
                      boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                      transition: "box-shadow 0.3s",
                      marginBottom: "8px",
                      cursor: "pointer",
                    }}
                  >
                    {message.messageType === "text" ? (
                      <p style={{ margin: "0" }}>{message.text}</p>
                    ) : message.messageType === "interactive" &&
                      message.interactive.type === "button" ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "8px",
                        }}
                      >
                        <p style={{ margin: "0" }}>
                          {message.interactive.body.text}
                        </p>
                        {message.interactive.action.buttons.map(
                          (btn, btnIndex) => (
                            <button
                              key={btnIndex}
                              style={{
                                padding: "8px",
                                backgroundColor: "#4CAF50",
                                color: "white",
                                border: "none",
                                borderRadius: "4px",
                                cursor: "pointer",
                              }}
                            >
                              {btn.reply.title}
                            </button>
                          )
                        )}
                      </div>
                    ) : message.messageType === "interactive" &&
                      message.interactive.type === "button_reply" ? (
                      <p>{message.interactive.button_reply.title}</p>
                    ) : message.messageType === "media" &&
                      message.interactive === null ? (
                      <p style={{ display: "flex", alignItems: "center" }}>
                        <MdPermMedia
                          fontSize={"18px"}
                          style={{ marginRight: "8px" }}
                        />
                        Unsupported Media
                      </p>
                    ) : message.messageType === "interactive" &&
                      message.interactive.type === "list" ? (
                      <>
                        <p>{message.interactive.body.text}</p>
                        <button
                          style={{ padding: "8px 12px", margin: "8px 0" }}
                        >
                          <HamburgerIcon style={{ marginRight: "8px" }} />{" "}
                          Select
                        </button>
                      </>
                    ) : message.messageType === "interactive" &&
                      message.interactive.type === "list_reply" ? (
                      <p>{message.interactive.list_reply.title}</p>
                    ) : message.messageType === "unsupported" &&
                      message.interactive === null ? (
                      <p style={{ display: "flex", alignItems: "center" }}>
                        <WarningIcon style={{ marginRight: "8px" }} />{" "}
                        Unsupported
                      </p>
                    ) : message.messageType === "flow" &&
                      message.interactive.type === "nfm_reply" ? (
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                        }}
                      >
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          {JSON.parse(
                            message.interactive.nfm_reply.response_json
                          )?.name ?? ""}
                        </span>
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                            gap: "6px",
                          }}
                        >
                          - Sent <CheckIcon fontSize={"small"} />
                        </span>
                      </p>
                    ) : message.messageType === "interactive" &&
                      message.interactive.type === "flow" ? (
                      <div>
                        <p style={{ padding: "8px" }}>
                          {message.interactive.body.text}{" "}
                        </p>
                        <button
                          style={{
                            padding: "8px",
                            backgroundColor: "#4CAF50",
                            color: "white",
                            border: "none",
                            borderRadius: "4px",
                            cursor: "pointer",
                            width: "100%",
                          }}
                        >
                          {message.interactive.action.parameters.flow_cta}
                        </button>
                      </div>
                    ) : (
                      "else case"
                    )}
                    <p
                      style={{
                        fontSize: "0.8em",
                        color: "#888",
                        margin: "5px 0",
                        textAlign: "right",
                      }}
                    >
                      {message.time}
                    </p>
                  </div>
                ))
              ) : (
                <h2
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {userNumber && loadingChat === "no-chat" ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CloseIcon
                        color="red"
                        fontSize={"medium"}
                        marginRight={"8px"}
                      />
                      {`No Chats Available For :-  +${userNumber}`}
                    </div>
                  ) : (
                    "Loading Chats..."
                  )}
                </h2>
              )}
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={userLocationModal}
        sx={{
          maxHeight: "500px !important",
          heigth: "500px !important",
          maxWidth: "400px !important",
          width: "400px !important",
          overflow: "scroll",
          padding: "8px",
          borderRadius: "8px",
          border: "1px solid darkgray",
        }}
        overlayClassName="custom-overlay"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Text>User Locations </Text>
            <CloseIcon
              onClick={() => {
                setUserLocationModal(false);
              }}
            />
          </ModalHeader>
          <ModalBody>
            {userLocationList.length ? (
              userLocationList.map((item) => {
                console.log("item ->", item);
                return (
                  <div style={{ display: "flex", gap: "16px" }}>
                    <p style={{ fontWeight: "bold" }}> {Object.keys(item)} </p>{" "}
                    : <span> {Object.values(item)}</span>
                  </div>
                );
              })
            ) : (
              <h2>No Locations Found</h2>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default User;
