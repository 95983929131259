const useApiCallHook = () => {
  const getUser = async (dates) => {
    try {
      const response = await fetch(
        `https://dashboard.remindr.bot/api/admin/users?${dates ? dates : ""}`
      );
      const data = await response.json();
      return { data };
    } catch (err) {
      return err;
    }
  };

  const getSubscriptionReport = async (number) => {
    try {
      const response = await fetch(
        `https://dashboard.remindr.bot/api/admin/subscription-report/${number}`
      );
      const data = await response.json();
      return data;
    } catch (err) {
      return err;
    }
  };

  const getWhatsappLogs = async (number) => {
    try {
      const response = await fetch(
        `https://dashboard.remindr.bot/api/admin/whatsapp-logs/${number}`
      );
      const data = await response.json();
      return data;
    } catch (err) {
      return err;
    }
  };

  const getPaymentHistory = async (dates) => {
    try {
      const response = await fetch(
        `https://dashboard.remindr.bot/api/admin/payment-history?${
          dates ? dates : ""
        }`
      );
      const data = await response.json();
      return data;
    } catch (err) {
      return err;
    }
  };

  const getReminderHistory = async (number) => {
    try {
      const response = await fetch(
        `https://dashboard.remindr.bot/api/admin/reminder-history/${number}`
      );
      const data = await response.json();
      return data;
    } catch (err) {
      return err;
    }
  };

  const getDashboardStats = async () => {
    try {
      const response = await fetch(
        "https://dashboard.remindr.bot/api/admin/dashboard"
      );
      const data = await response.json();
      return data;
    } catch (err) {
      return err;
    }
  };

  const getAnalyticsData = async (dates) => {
    try {
      const response = await fetch(
        `https://dashboard.remindr.bot/api/admin/analytics?${
          dates ? dates : ""
        }`
      );
      const data = await response.json();
      return data;
    } catch (err) {
      return err;
    }
  };

  const getLoginToken = async (body) => {
    try {
      const response = await fetch(
        "https://dashboard.remindr.bot/api/admin/login",
        {
          headers: {
            "Content-Type": "application/json",
            "sec-fetch-dest": "empty",
            "sec-fetch-mode": "cors",
            "sec-fetch-site": "cross-site",
          },
          body: JSON.stringify(body),
          method: "POST",
        }
      );
      const data = await response.json();
      return data;
    } catch (err) {
      return err;
    }
  };
  const getUserDashboardData = async (dates) => {
    try {
      const response = await fetch(
        `https://dashboard.remindr.bot/api/admin/user-dashboard?${
          dates ? dates : ""
        }`
      );
      const data = await response.json();
      return data;
    } catch (err) {
      return err;
    }
  };

  const getPaymentDashboardData = async (dates) => {
    try {
      const response = await fetch(
        `https://dashboard.remindr.bot/api/admin/payment-dashboard?${
          dates ? dates : ""
        }`
      );
      const data = await response.json();
      return data;
    } catch (err) {
      return err;
    }
  };

  const getUserLocations = async () => {
    try {
      const response = await fetch(
        "https://dashboard.remindr.bot/api/admin/user-location"
      );
      const data = await response.json();
      return data;
    } catch (err) {
      return err;
    }
  };

  https: return {
    getUser,
    getSubscriptionReport,
    getWhatsappLogs,
    getPaymentHistory,
    getReminderHistory,
    getDashboardStats,
    getAnalyticsData,
    getLoginToken,
    getUserDashboardData,
    getPaymentDashboardData,
    getUserLocations,
  };
};

export default useApiCallHook;
