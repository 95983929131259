import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "../App";
import UserDetailPage from "../Components/UserDetailPage";
import LoginPage from "../Components/LoginPage";

export default function PageRoute() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoginPage />}></Route>
        <Route path="/dashboard" element={<App />}></Route>
        <Route path="/userDetails/:number" element={<UserDetailPage />}></Route>
      </Routes>
    </BrowserRouter>
  );
}
