import React from "react";
import { CiSearch } from "react-icons/ci";

function SearchBar({ setSearchValues }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "fit-content",
        position: "relative",
      }}
    >
      <span
        style={{
          pointerEvents: "none",
          position: "absolute",
          marginLeft: "12px",
        }}
      >
        <CiSearch style={{ fontSize: "24px" }} />
      </span>
      <input
        type="search"
        placeholder="Search User by name"
        style={{
          height: "50px",
          width: "350px",
          borderRadius: "28px",
          outline: "none",
          padding: "20px 20px 20px 48px",
          border: "1px solid darkgray",
          fontSize: "18px",
          fontWeight: "500",
        }}
        onChange={(e) => setSearchValues(e.target.value)}
      />
    </div>
  );
}

export default SearchBar;
